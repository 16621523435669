import { api } from '@/../config'
import Vue from 'vue'
import axios from 'axios'

const state = { status: '', profile: {} }

const getters = {
  getProfile: state => state.profile,
  getUserId: state => state.profile._id,
  getUsername: state => state.profile.username,
  getEmail: state => state.profile.email,
  getCoins: state => state.profile.coins,
  getCards: state => state.profile.cards,
  getTrades: state => state.profile.trades,
  getFriends: state => state.profile.friends,
  getIcon: state => state.profile.icon && state.profile.icon.image,
  getIconId: state => state.profile.icon && state.profile.icon._id,
  isProfileLoaded: state => !!state.profile.email,
  isAdmin: state => state.profile.admin,
  getActivationToken : state => state.profile && state.profile.activation_token,
  getNotifications : state => state.profile.notifications,
  getNotificationsNbr : state => state.profile.notifications && (state.profile.notifications.friendRequests.length + state.profile.notifications.historic.length + state.profile.notifications.trades.length)
}

const actions = {
  USER_REQUEST: async ({ commit, dispatch }) => {
    commit('USER_REQUEST')
    try {
      const userRequest = await axios.post(api + 'auth/me')
      commit('USER_SUCCESS', userRequest.data.profile)
    }catch(err) {
      commit('USER_ERROR')
      commit('USER_LOGOUT')
      dispatch('AUTH_LOGOUT')
    }
  },
  USER_UPDATE_PROFILE: async ({ commit }, payload) => {
    try{
      commit('USER_SUCCESS', payload.user)
    }catch(err){
      commit('USER_ERROR')
    }
  },
  update_profile: async({commit,getters},payload)=>{
    await commit('USER_REQUEST')
    try{
      const user = {
        firstname: payload.user.firstname,
        lastname: payload.user.lastname,
        username: payload.user.username,
        icon: payload.user.icon,
        _id: getters.getProfile._id,
        email: getters.getEmail
      }
      const res = await axios.post(api + 'auth/me/update',{ user, recaptcha: payload.recaptcha })
      commit('USER_SUCCESS', res.data.updatedProfile)
      return true
    }catch(err){
      commit('USER_ERROR')
      throw err
    }
  },
  remove_notifications: async({commit,getters},payload)=>{
    await commit('USER_REQUEST')
    try{
      const notifications = getters.getNotifications
      notifications[payload.type] = []
      const res = await axios.post(api + 'auth/me/update',{ user: { _id: getters.getProfile._id, notifications }, recaptcha: payload.recaptcha })
      commit('USER_SUCCESS', res.data.updatedProfile)
      return true
    }catch(err){
      commit('USER_ERROR')
      throw err
    }
  },
  remove_friend: async({commit},payload)=>{
    await commit('USER_REQUEST')
    try{
      const res = await axios.delete(`${api}auth/friend/${payload.userId}`,{ recaptcha: payload.recaptcha })
      commit('USER_SUCCESS', res.data.updatedProfile)
      return true
    }catch(err){
      commit('USER_ERROR')
      throw err
    }
  },
  set_password : async ( { commit, getters }, payload ) => {
    await axios.post(`${api}public/activate-account/${getters.getActivationToken}`,{password: payload.user.password, recaptcha : payload.recaptcha })
    commit('activation_done')
    return 
  },
  remove_activation_token : async ( { commit } ) => {
    commit('activation_done')
    return
  },
  recieve_coins : ( { commit }, coins ) => {
    const repeat = setInterval(() => {
      if(coins > 0) {
        commit('recieve_coin')
        coins--
      } else {
        clearInterval(repeat)
      }
    }, 50)
    return
  },
  remove_coins : ( { commit }, coins ) => {
    const repeat = setInterval(() => {
      if(coins > 0) {
        commit('remove_coin')
        coins--
      } else {
        clearInterval(repeat)
      }
    }, 50)
    return
  },
}

const mutations = {
  ['USER_REQUEST']: state => {
    state.status = 'loading'
    return
  },
  ['USER_SUCCESS']: (state, profile) => {
    state.status = 'success'
    Vue.set(state, 'profile', profile)
  },
  ['USER_ERROR']: state => {
    state.status = 'error'
  },
  ['USER_LOGOUT']: state => {
    state.profile = {}
  },
  activation_done: state => {
    state.profile.activationToken = ''
  },
  recieve_coin: state => {
    state.profile.coins++
  },
  remove_coin: state => {
    state.profile.coins--
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
