<template>
  <v-app-bar id="navbar" app :class="{'scrolling':scrolling}">
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" class="ma-0" color="white" @click.stop="$emit('drawer')"></v-app-bar-nav-icon>
    <router-link class="d-flex align-center" to="/">
      <img class="logo mr-2" :src="`/img/${$vuetify.breakpoint.smAndDown?'logo_responsive':'souls_cards_logo'}.png`" alt="Souls Cards logo"/>
    </router-link>
    <v-spacer></v-spacer>
    <router-link class="d-none d-md-block nav-item mx-4 text-h6 font-weight-bold text-uppercase" v-for="item in navItems" :key="item.link" :to="item.link">{{item.text}}</router-link>
    <v-spacer></v-spacer>
    <div v-if="!$store.getters.isAuthenticated" class="d-flex">
      <router-link class="d-block nav-item mx-2 text-h6 font-weight-bold" to="/login">LOGIN</router-link>
    </div>
    <div v-else-if="$store.state.auth.status === 'loading' || $store.state.user.status == 'loading'" class="d-flex align-center">
      <v-skeleton-loader class="skeleton-coins ma-0 mr-2" type="text"></v-skeleton-loader>
      <v-skeleton-loader type="avatar"></v-skeleton-loader>
    </div>
    <div v-else class="d-flex align-center">
      <v-menu :close-on-content-click="$vuetify.breakpoint.smAndDown ? false : true" content-class="nav-account-menu elevation-0" bottom transition="slide-y-transition" nudge-bottom="50">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-avatar v-bind="attrs" v-on="{ ...menu }">
            <img :src="$store.getters.getIcon || '/img/icon-default.jpg'" alt="User's icon"/>
          </v-avatar>
        </template>
        <div class="ma-2 pa-2 rounded-lg bg-main-light">
          <sc-logout></sc-logout>
        </div>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'nav-bar',
  props: ['navItems'],
  data() {
    return {
      scrolling: false
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.scrolling = window.top.scrollY !== 0
    }
  }
}
</script>

<style lang="scss">
.v-menu__content {
  z-index: 10000!important;
}
#navbar {
  background: rgba($blacklight,0.80)!important;
  transition: all ease 0.3s;
  height: 76px!important;
  .logo {
    height: 24px;
  }
  .skeleton-coins {
    width: 5rem;
    .v-skeleton-loader__text {
      height: 1.5rem;
      margin-bottom: 0;
    }
  }
  .avatar {
    height: 36px;
    border-radius: 100%;
  }
  .v-toolbar {
    &__content {
      transition: all ease 0.3s;
      height: 76px!important;
      padding-right: 3rem;
      padding-left: 3rem;
      @include mobile {
        padding-left: 1rem;
        padding-right: 2rem;
      }
    }
  }
  &.scrolling {
      height: 64px!important;
    .v-toolbar__content {
      height: 64px!important;
    }
  }
  .nav-item {
    position: relative;
    &:after {    
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: 50%;
      position: absolute;
      background: #fff;
      transition: width 0.3s ease 0s, left 0.3s ease 0s;
      width: 0;
    }
    &:hover:after { 
      width: 100%; 
      left: 0; 
    }
  }
}
.v-avatar {
  border: $secondary 2px solid;
}
</style>