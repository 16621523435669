<template>
  <div class="sc-not-banner rounded-lg pa-2">
    <div :class="[`type-${srcImg}`,'img-container rounded']">
      <img :src="`/img/icons/${srcImg}.png`"/>
    </div>
    <span class="text">{{text}}</span>
    <v-btn @click="$store.dispatch('close_notif')" icon><v-icon>mdi-close</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  props: ['type','text'],
  name: 'notification',
  computed: {
    srcImg() {
      if(this.type === 'trade' || this.type === 'historic') return 'trade'
      else return 'praise'
    }
  }
}
</script>

<style lang="scss">
.sc-not-banner {
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  margin-top: calc(76px + 32px);
  margin-right: 20px;
  border: $secondary 1px solid;
  background-color: $blacklight;
  display: flex;
  align-items: center;
  max-width: 350px;
  .img-container {
    &.type-trade {
      background-image: linear-gradient(0deg, #493a1d 0%, rgba(255,255,255,0) 50%, #493a1d 100%);
      background-color: #5a451e;
    }
    &.type-friendRequest {
      background-image: linear-gradient(0deg, #3b2822 0%, rgba(255,255,255,0) 50%, #3b2822 100%);
      background-color: #4c2418;
    }
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 2rem;
    }
  }
  .text {
    margin-left: 8px;
    padding-right: 4px;
  }
}
</style>