import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{class:{'scrolling':_vm.scrolling},attrs:{"id":"navbar","app":""}},[(_vm.$vuetify.breakpoint.smAndDown)?_c(VAppBarNavIcon,{staticClass:"ma-0",attrs:{"color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('drawer')}}}):_vm._e(),_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c('img',{staticClass:"logo mr-2",attrs:{"src":`/img/${_vm.$vuetify.breakpoint.smAndDown?'logo_responsive':'souls_cards_logo'}.png`,"alt":"Souls Cards logo"}})]),_c(VSpacer),_vm._l((_vm.navItems),function(item){return _c('router-link',{key:item.link,staticClass:"d-none d-md-block nav-item mx-4 text-h6 font-weight-bold text-uppercase",attrs:{"to":item.link}},[_vm._v(_vm._s(item.text))])}),_c(VSpacer),(!_vm.$store.getters.isAuthenticated)?_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"d-block nav-item mx-2 text-h6 font-weight-bold",attrs:{"to":"/login"}},[_vm._v("LOGIN")])],1):(_vm.$store.state.auth.status === 'loading' || _vm.$store.state.user.status == 'loading')?_c('div',{staticClass:"d-flex align-center"},[_c(VSkeletonLoader,{staticClass:"skeleton-coins ma-0 mr-2",attrs:{"type":"text"}}),_c(VSkeletonLoader,{attrs:{"type":"avatar"}})],1):_c('div',{staticClass:"d-flex align-center"},[_c(VMenu,{attrs:{"close-on-content-click":_vm.$vuetify.breakpoint.smAndDown ? false : true,"content-class":"nav-account-menu elevation-0","bottom":"","transition":"slide-y-transition","nudge-bottom":"50"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c(VAvatar,_vm._g(_vm._b({},'v-avatar',attrs,false),{ ...menu }),[_c('img',{attrs:{"src":_vm.$store.getters.getIcon || '/img/icon-default.jpg',"alt":"User's icon"}})])]}}])},[_c('div',{staticClass:"ma-2 pa-2 rounded-lg bg-main-light"},[_c('sc-logout')],1)])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }