module.exports = {
  env : process.env.VUE_APP_ENV, 
  api : process.env.VUE_APP_API,
  recaptcha : process.env.VUE_APP_RECAPTCHA,
  algolia : {
    appId: process.env.VUE_APP_ALGOLIA_ID,
    apiKey: process.env.VUE_APP_ALGOLIA_KEY,
    index: process.env.VUE_APP_ALGOLIA_INDEX
  },
  emailJS: {
    serviceID: process.env.VUE_APP_SERVICE_ID,
    templateId: process.env.VUE_APP_TEMPLATE_ID,
    publicKey: process.env.VUE_APP_PUBLIC_KEY
  }
}
