<template>
  <v-btn text @click="logout" class="wi-100"><v-icon left>mdi-logout</v-icon>logout</v-btn>
</template>

<script>
export default {
  name: 'logout',
  data() {
    return {
    }
  },
  methods: {
    async logout() {
      try {
        this.$store.dispatch('start_g_loading')
        await this.$store.dispatch('AUTH_LOGOUT')
        this.$store.dispatch('display_infos', {success: true, text: 'Your are successfully disconnected.'})
        if(this.$route.path !== '/') this.$router.push('/')
      } catch(err) {
        console.log(err)
      } finally {
        this.$store.dispatch('stop_g_loading')
      }
    }
  }
}
</script>