import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next, cb) => {
  if (store.getters.isAuthenticated) {
    if(cb) return cb(to, from, next)
    next()
    return
  }
  store.dispatch('set_pending', to.fullPath)
  next('/login')
}
function meta(title){
  const hyphen = title ? ' - ':''
  return{
    title: `${title}${hyphen}SC Admin`,
  }
}
    
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: meta('Home')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    beforeEnter: ifNotAuthenticated,
    meta: meta('Login')
  },
  {
    path: '/all/decks',
    name: 'All Decks',
    component: () => import('@/views/admin/decks/All.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('All Decks')
  },
  {
    path: '/edit/decks/:deckId',
    name: 'Edit Deck',
    component: () => import('@/views/admin/decks/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Edit Deck')
  },
  {
    path: '/new/decks',
    name: 'New Deck',
    component: () => import('@/views/admin/decks/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('New Deck')
  },
  {
    path: '/all/cards',
    name: 'All Cards',
    component: () => import('@/views/admin/cards/All.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('All Cards')
  },
  {
    path: '/edit/cards/:cardId',
    name: 'Edit Card',
    component: () => import('@/views/admin/cards/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Edit Card')
  },
  {
    path: '/new/cards',
    name: 'New Card',
    component: () => import('@/views/admin/cards/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('New Card')
  },
  {
    path: '/all/categories',
    name: 'All Categories',
    component: () => import('@/views/admin/categories/All.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('All Categories')
  },
  {
    path: '/all/rarities',
    name: 'All Rarities',
    component: () => import('@/views/admin/rarities/All.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('All Rarities')
  },
  {
    path: '/all/icons',
    name: 'All Icons',
    component: () => import('@/views/admin/icons/All.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('All Icons')
  },
  {
    path: '/edit/icons/:iconId',
    name: 'Edit Icon',
    component: () => import('@/views/admin/icons/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('Edit Icon')
  },
  {
    path: '/new/icons',
    name: 'New Icon',
    component: () => import('@/views/admin/icons/New.vue'),
    beforeEnter: ifAuthenticated,
    meta: meta('New Icon')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
