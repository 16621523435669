<template>
  <div class="sc-footer">
    <div class="bottom pa-4 f-column f-center">
      <p class="text-center text-caption mb-4">ADMINISTRATOR WEBSITE</p>
      <p class="ma-0 text-caption">Made by <strong>HollowSTD ∀</strong> {{new Date().getFullYear()}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sc-footer',
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
.sc-footer {
  .top {
    background-color: rgb(5, 5, 5);
  }
  .bottom {
    background-color: black;
  }
}
</style>